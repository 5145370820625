import request from "@/utils/request";

// 新增分账比例商户
export function goSaveSplitRatio(data) {
  return request("post", "/apm/share/ratio/save", data);
}

// 分账比例商户列表
export function getSplitRatioList(data) {
    return request("post", "/apm/share/ratio/page", data);
}

// 移除分账比例商户
export function goRemoveSplitRatio(data) {
    return request("post", "/apm/share/ratio/removeById", data);
  }

  // 抽成设置
export function goSaveSplitRatioSetting(data) {
    return request("post", "/apm/share/ratio/setting", data);
  }

    // 抽成设置
export function getSplitRatioSettingList(data) {
    return request("post", "/apm/share/ratio/listByShopId", data);
  }
  